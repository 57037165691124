<template>
  <div class="main">
    <div class="box justify center mB15">
      <div class="search box center">
        <el-input v-model="searchTxt" placeholder="请输入资料名称" suffix-icon="el-icon-search"></el-input>
        <el-button @click="searchData">查询</el-button>
        <el-button @click="resetData">重置</el-button>
      </div>
      <el-button @click="showWin" :class="!isTrue ? 'disable_icon' : ''"><span class="iconfont iconjiahao"></span><span>上传资料</span></el-button>
    </div>
    <el-table v-if="tableData.length" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="originalName" label="桥梁资料" width="260px"></el-table-column>
      <el-table-column align="center" prop="timeO" label="上传时间"></el-table-column>
      <el-table-column align="center" prop="score" label="分数" v-if="tableData[0].score"></el-table-column>
      <el-table-column align="center" width="180px" label-class-name="option-btn" prop="id" label="操作">
        <!-- <template slot-scope="scope">
          <svg @click="downFile(scope.row)" class="icon fs22 operate-txt" aria-hidden="true" :class="!isTrue ? 'disable_icon' : ''">
            <use xlink:href="#iconxiazai"></use>
          </svg>
          <svg @click="deleteFile(scope.row)" class="icon fs22 ml24 delete-txt" aria-hidden="true" :class="!isTrue ? 'disable_icon' : ''">
            <use xlink:href="#iconshanchu"></use>
          </svg>
        </template> -->
        <template slot-scope="scope">
          <div class="btn-wrapper">
            <span type="text" class="colorText pointer" size="small" @click="downFile(scope.row)">下载</span>
            <span type="text" class="colorText pointer" size="small" @click="check(scope.row)" v-if="extArr.includes(scope.row.type)">查看</span>
            <span type="text" class="colorText pointer" size="small" v-else>-</span>
            <span type="text" class="colorText pointer" size="small" @click="deleteFile(scope.row)">删除</span>
          </div>
        </template>
      </el-table-column>
      <template slot="empty">
        <div>
          <img src="../../../assets/images/noData.png" alt="" class="imgver" />
          <span>暂无数据</span>
        </div>
      </template>
    </el-table>
    <common-pagination v-if="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" />
    <!-- <div id="example" style="border: 1px solid red; height: 100%"></div> -->
    <!-- <embed src="../../../assets/pdf/a.pdf" type="application/pdf" width="100%" height="100%" /> -->
    <!-- <object data="../../../assets/pdf/a.pdf#page=2" type="application/pdf" width="100%" height="100%" style="border: 1px solid blue">
      <iframe src="../../../assets/pdf/a.pdf#page=2" width="100%" height="100%" style="border: 1px solid yellow">
        This browser does not support PDFs. Please download the PDF to view it:
        <a href="../../../assets/pdf/a.pdf">Download PDF</a>
      </iframe>
    </object> -->
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <div v-if="type == 2" class="box center mB10" style="padding-left: 110px">
        <div class="fs16" style="color: #fff; margin-right: 40px">分数</div>
        <el-input v-model="fenshu" @input="inputFenshu(arguments[0], 100)" @change="inputFenshu(arguments[0], 100)"></el-input>
      </div>
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="180px">
        <!-- <el-form-item label="文件标题" prop="title">
              <el-input placeholder="请输入文件标题" v-model="ruleForm.title" />
            </el-form-item> -->
        <el-form-item label="文件附件" prop="file_arr">
          <el-upload action=" " multiple :limit="1" :file-list="fileListAdd" :on-remove="handleAddRemove" :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
            <el-button v-show="!hideUploadAdd" size="small" type="primary" class="select-btn">选择</el-button>
            <span slot="tip" class="el-upload__tip">上传文件不超过50M</span>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="分数" prop="file_arr">
          <el-input v-model="fenshu"></el-input>
        </el-form-item> -->

        <el-form-item>
          <span class="sureBtn back-btn pointer mR30" @click="back">取消</span>
          <span class="backBtn submit-btn pointer" @click="submitForm">确定</span>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="查看文件" :visible.sync="pdfVisible" width="1200px" :before-close="handlePdfClose" custom-class="pdf-class">
      <div class="pdf-wrapper">
        <div class="pdf-title" v-show="!downImgUrl">
          <el-button size="small" type="primary" @click="nextPdfPage('prev')">上一页</el-button>
          {{ currentPdfPage }} / {{ pagePdfCount }}
          <el-button size="small" type="primary" @click="nextPdfPage('next')">下一页</el-button>
        </div>

        <pdf v-show="!downImgUrl" :src="pdfUrl" @num-pages="pagePdfCount = $event" @page-loaded="currentPdfPage = $event" :page="currentPdfPage"></pdf>
        <img v-show="downImgUrl" :src="downImgUrl" alt="" class="down-img" />
        <div class="pdf-btm">
          <span class="sureBtn back-btn pointer mR30" @click="handlePdfClose">关闭</span>
          <!-- <span class="backBtn submit-btn pointer" @click="submitForm">确定</span> -->
        </div>
      </div>
    </el-dialog>
    <confirm :detWin="errWin" :msg="msg" @confirm="errbtn" @close="errClose"></confirm>
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
import upload from "@/assets/js/upload.js";
// import PDFObject from "pdfobject";
import pdf from "vue-pdf";
import confirm from "../../../components/confirm";
export default {
  name: "documentList",
  components: {
    CommonPagination,
    pdf,
    confirm
  },
  data() {
    return {
      deleteId: "",
      msg: "",
      errWin: 0,
      downImgUrl: "",
      currentPdfPage: 1,
      pagePdfCount: 0,
      numPages: undefined,
      pdfUrl: "",
      pdfVisible: false,
      newFile: new FormData(), //
      projectId: "",
      pageSize: 10, //
      currentPage: 1,
      total: 0,
      loading: false,
      searchTxt: "",
      dialogVisible: false,
      actionURL: "",
      fileList: [],
      ruleForm: { title: "", file_arr: [] },
      rules: {
        // title: [
        //   { required: true, message: "请输入文件标题", trigger: "blur" },
        //   { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        // ],
        file_arr: [{ type: "array", required: true, message: "请选择文件", trigger: "blur" }]
      },
      tableData: [],
      type: "1",
      list: "", //数据列表
      up: "", //上传
      extArr: [".png", ".jpg", ".jpeg", ".svg", ".pdf"],
      // extArr: [".docx", ".doc", ".xls", ".xlsx"],

      fileListAdd: [],
      hideUploadAdd: false,
      loadingPage: null,
      pathId: "",
      isTrue: "",
      fenshu: "100" //分数
      // disabled: false,
    };
  },
  created() {
    console.log(this.$route.params, this.$route.query);
    if (this.$route.params.id) {
      this.type = this.$route.params.id;
    }
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      var path = to.path;
      var path1 = path.split("/");
      console.log("养护信息", path1[2]);
      this.type = path1[2]; //1日常巡检   2定期检查   3特殊监测  4荷载试验  5桥梁养护
      console.log(`type`, this.type);
      this.changeType();
    }
  },
  mounted() {
    //  this.isTrue = sessionStorage.getItem('isTrue')=='true' ? false : true

    this.pathId = sessionStorage.getItem("zi");
    this.projectId = sessionStorage.getItem("projectId");
    this.changeType();
    setTimeout(() => {
      this.getDataList();
    }, 50);

    //判断是否可以操作
    // this.operate();
    this.isTrue = sessionStorage.getItem("isTrue");
  },
  methods: {
    inputFenshu(val, max) {
      if (!val) {
        this.fenshu = 0;
        return;
      }
      this.fenshu = parseInt(this.fenshu.replace(/[^\d.]/g, ""));
      console.log("输入数", parseInt(val), max, this.fenshu);
      // onkeyup="this.value=this.value.replace(/[^\d.]/g,'')
      this.$nextTick(() => {
        let num = Math.min(this.fenshu, max);
        console.log("num", num, "max", max);
        if (num < 0) {
          this.fenshu = 0;
        } else {
          this.fenshu = num;
        }
      });
    },

    nextPdfPage(flag) {
      switch (flag) {
        case "next":
          if (this.currentPdfPage >= this.pagePdfCount) {
            this.currentPdfPage = this.pagePdfCount;
            return;
          }
          this.currentPdfPage++;
          break;
        case "prev":
          if (this.currentPdfPage <= 1) {
            this.currentPdfPage = 1;
            return;
          }
          this.currentPdfPage--;
      }
    },
    handlePdfClose() {
      this.pdfVisible = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    showWin() {
      console.log("type", this.type);

      if (!this.isTrue) return;
      this.dialogVisible = true;
      this.hideUploadAdd = false;
    },

    //返回
    back() {
      this.handleClose();
    },

    //查询
    searchData() {
      this.currentPage = 1;
      this.getDataList();
    },

    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        this.ruleForm.file_arr = this.fileListAdd;
        this.$refs.ruleForm.validate((valid) => {});
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 1) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },
    // 确定新增
    submitForm() {
      this.ruleForm.file_arr = this.fileListAdd;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let formData = new FormData();
          // formData.append("title", this.ruleForm.title);
          this.fileListAdd.map((item) => {
            formData.append("file", item.raw);
          });
          var path = "";
          if (this.type == 2) {
            path = `sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}&score=${this.fenshu}`;
          } else {
            path = `sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}`;
          }

          // this.$axios.post(`${this.baseURL}sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}`, formData).then((res) => {
          this.$axios.post(`${this.baseURL}${path}`, formData).then((res) => {
            this.loadingPage.close();
            this.$message({
              message: "上传成功",
              type: "success"
            });

            this.handleClose();
          });
        }
      });
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.fileListAdd = [];
      });
      this.dialogVisible = false;
      this.getDataList();
    },

    beforeUpload(file) {
      console.log("file", file);
      if (file) {
        this.newFile.append("file", file); //  2. 上传之前，拿到file对象，并将它添加到刚刚定义的FormData对象中。
        console.log(this.newFile.get("file"));
        // return false
      } else {
        return false;
      }
    },

    //提交
    submitForm1() {
      this.$refs.upload.submit();
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      if (this.type == 2) {
        var path = `sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}&score=${this.fenshu}`;
      } else {
        var path = `sys/sysFile/${this.up}/import?bizId=${this.projectId}&originalName=${this.ruleForm.title}`;
      }
      this.$axios
        .post(`${this.baseURL}${path}`, this.newFile, config)
        .then((res) => {
          console.log("上传", res);
          this.handleClose();
        })
        .catch((err) => {});
    },
    handleClose1() {
      this.$nextTick(() => {
        this.ruleForm.title = "";
        this.fileList = [];
      });
      this.dialogVisible = false;
      this.getDataList();
    },

    successData(res, file) {
      console.log(res);
      const size = file.size / 1024 / 1024 > 0.1 ? `(${(file.size / 1024 / 1024).toFixed(1)}M)` : `(${(file.size / 1024).toFixed(1)}KB)`;
      file.name.indexOf("M") > -1 || file.name.indexOf("KB") > -1 ? file.name : (file.name += size);
      this.ruleForm.file_arr.push(res.data);
    },
    beforeRemove(file) {
      console.log("删除前");
      this.handleDeleteFile(file);
    },
    handleDeleteFile(file) {
      console.log("删除了");
      this.ruleForm.file_arr.forEach((item, index) => {
        if (item === file.response.data) {
          this.ruleForm.file_arr.splice(index, 1);
          return;
        }
      });
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getDataList();
    },
    //数据列表
    getDataList() {
      this.$axios.get(`${this.baseURL}sys/sysFile/${this.list}/name/${this.currentPage}/${this.pageSize}/${this.projectId}?name=${this.searchTxt}`).then((res) => {
        console.log("res表格数据返回", res);
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;

        //  if(this.tableData.length == 0){
        //         if( this.currentPage >1){
        //            this.currentPage =  this.currentPage -1;
        //            this.getDataList();
        //         }else{
        //           this.currentPage = 1;
        //            this.getDataList();
        //         }
        //   }
      });
    },
    errbtn() {
      this.errWin = 0;
      this.httpDel(this.deleteId);
    },
    errClose() {
      this.errWin = 0;
    },
    deleteFile(item) {
      if (this.isTrue != "true") {
        // this.$message({
        //   message: "无权限，请联系管理员!",
        //   type: "warning"
        // });
        return;
      }
      // this.deleteId = item.id
      // console.log('data', item, this.deleteId)
      // this.msg = `确认删除${item.originalName}？`

      // this.errWin = 1
      // console.log(item);
      this.$confirm(`<i class="el-icon-warning"></i>确认删除${item.originalName}？`, "确认删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          this.httpDel(item.id);
        })
        .catch(function () {});
    },
    httpDel(id) {
      this.$axios.delete(`${this.baseURL}sys/sysFile/${id}`).then((res) => {
        console.log("删除", res);
        this.$message({
          message: res.data.errMsg,
          type: "success",
          duration: 1000
        });
        this.currentPage = 1;
        this.getDataList();
      });
    },

    resetData() {
      //重置
      this.handleCurrentChange(1);
      this.currentPage = 1;
      this.searchTxt = "";
      this.getDataList();
    },
    check(row) {
      console.log("row", row);
      // 下载
      if (this.isTrue != "true") {
        // this.$message({
        //   message: "无权限，请联系管理员!",
        //   type: "warning"
        // });
        return;
      }

      let arr = [".png", ".jpg", ".jpeg", ".svg"];
      if (arr.includes(row.type)) {
        this.pdfVisible = true;
        this.downImgUrl = `${this.downloadURL}sys/sysFile/${row.id}`;
        console.log("downImgUrl", this.downImgUrl);
        return;
      }
      // if (row.type == ".docx" || row.type == ".doc") {
      //   var a = document.createElement("a");
      //   a.href = `${this.downloadURL}sys/sysFile/${row.id}`;
      //   a.click();
      //   return;
      // }

      this.pdfVisible = true;
      //  var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)
      // var a = document.createElement("a");
      this.downImgUrl = "";
      this.currentPdfPage = 1;
      this.pdfUrl = pdf.createLoadingTask(`${this.downloadURL}sys/sysFile/${row.id}`);
      this.pdfUrl.promise.then((pdf) => {
        console.log("numPages", this.numPages);
        this.numPages = pdf.numPages;
      });
      console.log("pafurl", this.pdfUrl);
      // a.href = `${this.downloadURL}sys/sysFile/${id}`;
      // const agent = window.navigator;
      // // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      // // }
      // a.click();
      // console.log(`${this.downloadURL}sys/sysFile/${id}`, PDFObject);
      // console.log("PDFObject", PDFObject);
      // PDFObject.embed(`../../../assets/pdf/a.pdf`, "#example");
    },

    downFile(row) {
      console.log("row", row);
      // 下载
      if (this.isTrue != "true") {
        // this.$message({
        //   message: "无权限，请联系管理员!",
        //   type: "warning"
        // });
        return;
      }

      // let arr = [".png", ".jpg", ".jpeg", ".svg"];
      // if (arr.includes(row.type)) {
      //   this.pdfVisible = true;
      //   this.downImgUrl = `${this.downloadURL}sys/sysFile/${row.id}`;
      //   console.log("downImgUrl", this.downImgUrl);
      //   return;
      // }
      // if (row.type == ".docx" || row.type == ".doc") {
      //   var a = document.createElement("a");
      //   a.href = `${this.downloadURL}sys/sysFile/${row.id}`;
      //   a.click();
      //   return;
      // }

      // this.pdfVisible = true;
      //  var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)
      var a = document.createElement("a");
      // this.downImgUrl = "";
      // this.currentPdfPage = 1;
      // this.pdfUrl = pdf.createLoadingTask(`${this.downloadURL}sys/sysFile/${row.id}`);
      // this.pdfUrl.promise.then((pdf) => {
      //   console.log("numPages", this.numPages);
      //   this.numPages = pdf.numPages;
      // });
      // console.log("pafurl", this.pdfUrl);
      a.href = `${this.downloadURL}sys/sysFile/${row.id}`;
      // const agent = window.navigator;
      // // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      // // }
      a.click();
      // console.log(`${this.downloadURL}sys/sysFile/${id}`, PDFObject);
      // console.log("PDFObject", PDFObject);
      // PDFObject.embed(`../../../assets/pdf/a.pdf`, "#example");
    },

    changeType() {
      if (this.type == 1) {
        var name = "日常巡检";
        this.$store.commit("getName3", name);
        sessionStorage.setItem("name3", name);
        this.list = "daily";
        this.up = "daily";
        this.currentPage = 1;
        this.getDataList();
      }
      if (this.type == 2) {
        var name = "定期检查";
        this.$store.commit("getName3", name);
        sessionStorage.setItem("name3", name);
        this.list = "periodic";
        this.up = "periodic";
        this.currentPage = 1;
        this.getDataList();
      }
      if (this.type == 3) {
        var name = "特殊检测";
        this.$store.commit("getName3", name);
        sessionStorage.setItem("name3", name);
        this.list = "special";
        this.up = "special";
        this.currentPage = 1;
        this.getDataList();
      }
      if (this.type == 4) {
        var name = "荷载试验";
        this.$store.commit("getName3", name);
        sessionStorage.setItem("name3", name);
        this.list = "load";
        this.up = "load";
        this.currentPage = 1;
        this.getDataList();
      }
      if (this.type == 5) {
        var name = "桥梁养护";
        this.$store.commit("getName3", name);
        sessionStorage.setItem("name3", name);
        this.list = "maintain";
        this.up = "maintain";
        this.currentPage = 1;
        this.getDataList();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}

::v-deep .el-table th.el-table__cell > .option-btn.cell {
  padding-right: 50px;
  box-sizing: border-box;
}

.down-img {
  width: 100%;
}

.main ::v-deep .el-dialog__wrapper div.pdf-class {
  width: 1200px !important;
  margin-top: 0px !important;
  max-height: calc(100vh - 8px);
  overflow: auto;
  margin: auto;

  .pdf-wrapper {
    box-sizing: border-box;
    text-align: center;

    img {
      margin: auto;
    }

    .pdf-btm {
      padding-top: 20px;
      text-align: center;
    }

    .pdf-title {
      padding-bottom: 15px;
      text-align: center;
    }
  }
}

.main {
  margin-top: 17px;
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb24 {
    margin-bottom: 24px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 15px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

::v-deep .el-button {
  // height: 35px !important;
}

::v-deep .el-input {
  height: 35px !important;
}

.back-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  // line-height: 32px;
}

.submit-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  //  line-height: 32px;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
